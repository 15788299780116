<template>
    
    <div class="box" id="skill_card">
      <img class="icon" src="@/assets/stats/profile_pic.jpg"/>
      <div class="health-bar">
        <p>HP: 100 <Progress color="green" percent="100" padding="5px" width="100%"></Progress></p>
        <p>MP: 100 <Progress color="blue" percent="100" padding="5px" width="100%"></Progress></p>
      </div>
      <div class="class">
        <p><b>Class: </b></p>
        <p>Cyber Security Red Teamer</p>
      </div>
      <div class="level">
        <p><b>Level: 2</b></p>
        <Progress color="#36A1FF" percent="50" padding="10px" width="100%"></Progress>
      </div>

      <div class="skills">
        <p><b>Skill Highlights:</b></p>
        <ul>
          <li>Red Team Operation</li>
          <li>Phishing Simluation</li>
          <li>Payload Crafting</li>
          <li>Penetration Testing</li>
        </ul>
      </div>
    </div>
</template>

<script>
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import Progress from './Progress-bar.vue';
    export default {
    name: "Intro",
    components: { Progress },
    mounted(){

      // Import modules
      /* Create chart instance */
      let chart = am4core.create("chartdiv", am4charts.RadarChart);

      /* Add data */
      chart.data = [{
        "category": "Web Application",
        "value": 278,
      }, {
        "category": "Phishing",
        "value": 400,
      }, {
        "category": "Internal Testing",
        "value": 345,
      }, {
        "category": "Mobile Testing",
        "value": 0,
      }, {
        "category": "Network Testing",
        "value": 148,
      }, {
        "category": "Tool Development",
        "value": 287,
      }];

      /* Create axes */
      let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "category";
      //Axis.renderer.grid.template.disabled = true;

      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.renderer.gridType = "polygons";
      valueAxis.renderer.labels.template.disabled = true;

      /* Create and configure series */
      let series = chart.series.push(new am4charts.RadarSeries());
      series.dataFields.valueY = "value";
      series.dataFields.categoryX = "category";
      series.name = "Sales";
      series.strokeWidth = 3;
      series.zIndex = 2;
      series.strokeWidth = 3;
      series.fillOpacity = 0.5;
      series.stroke = am4core.color("#9EC8B9");
      series.fill = am4core.color("#1B4242a");
    }
}
</script>




<style scoped>
  template{
    display: inline-flexbox;
    align-content: center;
  }
  
  .icon{
    width: 100%;
    height: auto;
    border-radius: 50%;
  }

  .level{
    text-align: left;
    align-items: center;
  }

  .class{
    text-align: left;
    align-items: center;
  }

  .skills{
    text-align: left;
    align-items: center;
  }

  #chartdiv {
	width		: 100%;
	height		: 500px;
	font-size	: 11px;
  margin: 50px 0 150px;
}

</style>